import React, { Component } from "react";
import { Helmet } from "react-helmet";
import WistiaEmbed from "../../components/WistiaEmbed/WistiaEmbed";
import Cookie from "../../components/Cookie/Cookie";

import Header from "../../components/Header/ClientHeader";
import Alert from "../../components/Header/AlertBanner";
import Banner from "../../components/Banner/ClientBanner";
import Steps from "../../components/Steps/Steps";
import Testimonials from "../../components/Testimonials/Testimonials";
import CTA from "../../components/CTA/CTA";
import Prevention from "../../components/Footer/Prevention";
import Footer from "../../components/Footer/Footer";
import appDemo from "../../assets/app-demo.png";
import parentAndTeen from "../../assets/bg-banner-teen-mobile.jpg";

import cdphp from "../../assets/insurance/cdphp.png";
import aetna from "../../assets/insurance/aetna.png";
import empirebcbs from "../../assets/insurance/empire-bcbs.png";
import mvp from "../../assets/insurance/mvp.png";
import unitedhealthcare from "../../assets/insurance/unitedhealthcare.png";
import fidelis from "../../assets/insurance/fidelis.png";
import highmark from "../../assets/insurance/highmark.png";
import nysempireplan from "../../assets/insurance/nys-empireplan.png";

class Cdphp extends Component {
  render() {
    const pageTitle = this.props.pageTitle;
    const partner = this.props.page;
    const steps = this.props.steps;
    const alert = this.props.alert ? <Alert /> : null;
    const alertClass = this.props.alert ? "show-alert" : null;

    return (
      <article className={alertClass}>
        <Helmet>
          <title>{"aptihealth & " + pageTitle}</title>
          <meta
            name="description"
            content="Your Fast Track to Better Mental Health. Receive personalized, goal-oriented teletherapy that empowers you to make positive life changes and feel better within 90 days."
          />
        </Helmet>

        <Header
          partner={partner}
          btnText="Log In"
          btnLink="https://home.aptihealth.com/auth/sign-in"
          btnStyle="alt"
        />
        {alert}
        <Banner page={partner} />

        <section id="introScreening" className="clientIntro">
          <div className="wrap">
            <CTA
              btnText="Get Started"
              btnLink="https://home.aptihealth.com/auth/self-signup"
            />
            <div className="copy">
              {partner === "cdphp" || partner === "CDPHP" ? (
                <h4>Availability in as little as 48 hours with $0 copay*</h4>
              ) : (
                <h4>
                  Receive <strong>personalized mental healthcare</strong>,{" "}
                  <strong>substance use therapy</strong>, and{" "}
                  <strong>care management</strong> that empowers you to make
                  positive life changes and feel better within 90 days.
                </h4>
              )}
            </div>
          </div>
        </section>

        <section className="pt60 clientPages">
          <div className="wrap">
            <div className="two-col pad">
              <div className="col">
                <h2 className="subhead">
                  It&rsquo;s Easy to Get Care with aptihealth
                </h2>
                <p>
                  All you need is a video-enabled smartphone, tablet, or
                  computer to access our secure portal.
                </p>
                <ul className="checks">
                  <li>Create Your Profile</li>
                  {/*<li>Verify Your Insurance Eligibility</li>*/}
                  <li>Schedule Your Assessment</li>
                  <li>Connect with Your Care Team</li>
                </ul>
                <CTA
                  btnText="Get Care"
                  btnLink="https://home.aptihealth.com/auth/self-signup"
                  btnStyle="alt lg"
                />
              </div>
              <div className="col">
                <img
                  src={appDemo}
                  className="demoPhoto"
                  alt="aptihealth iPhone Demo"
                />
              </div>
            </div>
          </div>
        </section>
        {partner !== "cdphp" && partner !== "CDPHP" && (
          <section className="grey p60">
            <h2 className="subhead" style={{ marginLeft: "60px" }}>
              We accept insurance. Get started to see if you're covered.
            </h2>
            <div className="insurance-image-list">
              <img src={cdphp} alt="CDPHP" />
              <img src={fidelis} alt="Fidelis" />
              <img src={mvp} alt="MVP" />
              <img src={empirebcbs} alt="Empire BCBS" />
              <img src={unitedhealthcare} alt="United Healthcare" />
              <img src={nysempireplan} alt="NYS Empire Plan" />
              <img src={highmark} alt="Highmark" />
              <img src={aetna} alt="Aetna" />
            </div>
          </section>
        )}

        <Steps partner={partner} />

        <section className="p60 teen">
          <div className="wrap">
            <div className="two-col">
              <div className="col">
                <img
                  src={parentAndTeen}
                  className="mobile"
                  alt="Teen with Parent"
                />
              </div>
              <div className="col">
                <h2 className="subhead">
                  aptihealth&rsquo;s Teens + Kids Care Program
                </h2>
                <p className="last">
                  Our Teens + Kids Care Program offers comprehensive care and
                  support for patients 5 and up. To register your child,
                  you&rsquo;ll first need to create your own aptihealth account.
                  Once registered, you’ll be prompted to create an account for
                  your child. A separate account allows your child the freedom
                  to message directly with their therapist and feel empowered to
                  manage their&nbsp;well-being. 
                </p>
                <CTA
                  btnText="Start My Registration"
                  btnLink="https://home.aptihealth.com/auth/self-signup"
                  btnStyle="outline lg"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="pt60 grey clientPages">
          <div className="wrap">
            <div className="two-col pad">
              <div className="col">
                <h2 className="subhead">
                  aptihealth Member Success&nbsp;Stories
                </h2>
                <p>
                  Watch Hannah’s inspiring journey to learn more about the
                  aptihealth experience.
                </p>
                <blockquote>
                  <p>
                    &ldquo;aptihealth hasn’t just improved my life, it
                    completely changed the way I’m able to interact in the
                    world. aptihealth helped me be able to see the beauty in the
                    world&nbsp;again.&rdquo;
                  </p>
                  <cite>
                    <strong>Hannah*</strong>, aptihealth Member
                  </cite>
                  <em>*Name has been changed to protect privacy.</em>
                </blockquote>
              </div>
              <div className="col">
                <WistiaEmbed hashedId="27a7jiak5v" playerColor="#0CD0A7" />
              </div>
            </div>
          </div>
        </section>

        <Prevention />
        <Footer />
        <Cookie />
      </article>
    );
  }
}
export default Cdphp;
