// Imports
import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import $ from 'jquery';

// Components
import Header from '../../components/Header/Header';
import Banner from '../../components/Banner/Banner';
import Footer from '../../components/Footer/Footer';

// Assets
import timeline from'../../assets/svg/grph-timeline-outline.svg';
import timelineMobile from'../../assets/svg/grph-timeline-mobile-outline.svg';

import insight from'../../assets/svg/icn-insight.svg';
import execution from'../../assets/svg/icn-execute.svg';
import results from'../../assets/svg/icn-results.svg';


import alex from'../../assets/team-alex.jpg';
import dan from'../../assets/team-dan.jpg';
import peter from'../../assets/team-peter.jpg';
import bruce from'../../assets/team-bruce.jpg';
import bob from'../../assets/team-bob.jpg';
import greg from'../../assets/team-greg.jpg';
import kevin from'../../assets/team-kevin.jpg';
import holly from'../../assets/team-holly.jpg';
import mary from'../../assets/team-mary.jpg';
import chris from'../../assets/team-chris.jpg';
import amanda from'../../assets/team-amanda.jpg';

// import kristen from'../../assets/team-kristen.jpg';
// import tyler from'../../assets/team-tyler.jpg';
// import carl from'../../assets/team-carl.jpg';
// import michaelf from'../../assets/team-michael-fallone.jpg';
// import regina from'../../assets/team-regina.jpg';

// import ashley from'../../assets/team-ashley.jpg';
// import elizabeth from'../../assets/team-elizabeth.jpg';
// import dale from'../../assets/team-dale.jpg';
// import michael from'../../assets/team-michael.jpg';
// import scott from'../../assets/team-scott.jpg';
// import koriAnn from'../../assets/team-kori-ann.jpg';
// import lisa from'../../assets/team-lisa.jpg';
// import hania from'../../assets/team-hania.jpg';
// import joe from'../../assets/team-joe.jpg';
// import sean from'../../assets/team-sean.jpg';
// import erin from'../../assets/team-erin.jpg';
// import weiner from'../../assets/team-sweiner.jpg';
// import joan from'../../assets/team-joan.jpg';



class About extends Component {

  componentDidMount() {
    $(document).ready(function(){
      $('.toggle').click(function(){
        var member = $(this).data('bio');
        if($(this).hasClass('open')){
          $('#'+member).removeClass('openMember');
          $(this).removeClass('open');
        } else {
          $('#'+member).addClass('openMember');
          $(this).addClass('open');
        }
      });
    });
  }

  render() {

    return (
      <article id="company">
				<Helmet>
					<title>Company - Everything we do at aptihealth is centered around one thing: care.</title>
					<meta name="description" content="We&rsquo;re on a mission to revolutionize behavioral healthcare to ensure that the millions of people who need it have access to high-quality care, without delay. Join us!"/>
          <meta property="og:url" content={window.location.href} />					
				</Helmet>
        
        <Header />
        <Banner page='page-about' heading='A Transformative Approach to Behavioral Healthcare is Here'/>

        
        <section className="p30">
          <div className="wrap">

            <div className="copy">
              <h3>Everything we do at aptihealth is centered around one thing: <strong>Care</strong>.</h3>
              <p>We&rsquo;re on a mission to revolutionize behavioral healthcare to ensure that the millions of people who need it have access to comprehensive care, without delay. We believe one&rsquo;s health shouldn&rsquo;t ever have to be compromised. We&rsquo;re driven by our company&rsquo;s three fundamental values:</p>
            </div>

            <div className="three-col values">
              <div className="col">
                <div>
                  <img src={insight} alt='Insight'/>
                  <h3>Insight</h3>
                  <p>We&rsquo;re an inspired team of innovative thinkers, relentless in our pursuit of visionary ideas.</p>
                </div>
              </div>
              <div className="col">
                <div>
                  <img src={execution} alt='Execution'/>
                  <h3>Execution</h3>
                  <p>We commit to turning ideas into actions that lead to fresh, groundbreaking solutions.</p>
                </div>
              </div>
              <div className="col">
                <div>              
                  <img src={results} alt='Results'/>
                  <h3>Results</h3>
                  <p>We don&rsquo;t rest until our actions deliver breakthrough results – outcomes that mean more for people.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        
        <section className="pt30 grey text-center">
          <div className="wrap">
              <h2>aptihealth Milestones</h2>
              <img src={timeline} className="timeline desktop" alt='aptihealth Milestones'/>
              <img src={timelineMobile} className="timeline mobile" alt='aptihealth Milestones'/>
          </div>
        </section>
        

        <section className="pt30">
          <div className="wrap">
            <h2 className="text-center">Leadership Team</h2>
            
            <div className="three-col team">

              <div className="col">
                <div id="dan" className="member">
                  <button className="toggle" data-bio='dan'><span>Read Bio</span></button>                
                  <div className="hold">
                    <img src={dan} alt='Daniel T. Pickett III'/>
                    <h3>Daniel T. Pickett III <span>Co-Founder &amp; Chief Executive Officer</span></h3>
                    <div className="bio">
                      <div>
                        <p>Dan Pickett III is a co-founder and the CEO of aptihealth. Dan joined aptihealth from Hudson River Capital Holdings, an aptihealth founding investor, where he was President. Prior to Hudson River Capital Holdings, he was President of Zones, a $2.4B global IT company. Dan joined Zones when it acquired nfrastructure, a technology company he founded and served as Chief Executive Officer and Chairman of the Board.</p>
                        <p>Prior to nfrastructure, Dan co-founded CIGNA Software Sciences, an expert system and decision support fintech company that was acquired by Alltel Information Services. He is a trustee of Rensselaer Polytechnic Institute and serves on the boards of aptihealth, The Pickett Family Foundation, Albany Medical Center and Albany Medical College.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>           

              <div className="col">
                <div id="greg" className="member">              
                  <button className="toggle" data-bio='greg'><span>Read Bio</span></button>                                
                  <div className="hold">
                    <img src={greg} alt='Greg Stephan' />
                    <h3>Greg Stephan <span>Chief Financial Officer</span></h3>
                    <div className="bio">
                      <div>
                        <p>Greg Stephan is the Chief Financial Officer of aptihealth. Before aptihealth, Greg served as General Manager and divisional CFO of Zones nfrastructure, a $2.5B global technology company. Prior to Zones, he was the head of Global Finance for Oracle&rsquo;s $850M hospitality business unit, overseeing sales operations and finance.</p>
                        <p>Previously, Greg was Vice President, Finance for Nielsen (formally Arbitron) and responsible for financial planning and analysis, strategy, and financial and business operations. Greg provided financial and operations stewardship through Arbitron&rsquo;s digital transformation to a technology-driven ratings service. He played a critical role in the transaction process which culminated in Arbitron&rsquo;s $1.4B acquisition by The Nielsen Company.</p>
                        <p>Greg is a Certified Public Accountant (CPA).</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>                           

              <div className="col">
                <div id="alex" className="member">
                  <button className="toggle" data-bio='alex'><span>Read Bio</span></button>                
                  <div className="hold">
                    <img src={alex} alt='Alex Marsal, PhD'/>
                    <h3>Alex Marsal, PhD <span>Co-Founder, Chief Clinical &amp; Science Officer</span></h3>
                    <div className="bio">
                      <div>
                        <p>Dr. Alex Marsal is a co-founder and the Chief Clinical and Science Officer of aptihealth. He also founded The Recovery Institute and Vanguard Behavioral Solutions aimed at innovating and field-testing patient self-assessments, therapeutic relationship building, and person centered, multidimensional behavioral health treatment. His leadership experience includes 10 years of hospital-based behavioral health administration which resulted in care delivery transformations yielding system efficiencies, improved outcomes, and greater patient satisfaction.</p>
                        <p>With over 30 years of behavioral health experience, Alex&rsquo;s expertise includes providing psychotherapy services to populations with dual-diagnosis, depressive and anxiety disorders, marital difficulties, and PTSD/abusive experiences. His research focus includes the assessment and treatment of high cost, high need populations. Alex is a member of the American Psychological Association and National Register of Health Service Providers in Psychology.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>                     

              <div className="col">
                <div id="kevin" className="member">              
                  <button className="toggle" data-bio='kevin'><span>Read Bio</span></button>                                
                  <div className="hold">
                    <img src={kevin} alt='Kevin Martin, MD' />
                    <h3>Kevin Martin, MD <span>Chief Psychiatric Officer</span></h3>
                    <div className="bio">
                      <div>
                        <p>Dr. Kevin Martin is the Chief Psychiatric Officer of aptihealth. He joined aptihealth from Four Winds Saratoga psychiatric hospital where he served as Chief Medical Officer. Prior to that he held senior leadership positions in community mental health and child welfare. </p>
                        <p>Dr. Martin is board certified in general adult psychiatry and child and adolescent psychiatry. A highly regarded clinical team leader, his 30 years of experience encompasses both adult and child/adolescent treatment in private and public settings across multiple levels of care, including outpatient, inpatient, residential, partial hospital, intensive outpatient, as well as consultation in school and juvenile justice settings. </p>
                        <p>In addition to his clinical work, he is a trainer and educator. He has taught physicians-in-training in pediatrics, adult psychiatry, and child/adolescent psychiatry, and presented to professional and lay audiences.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col">
                <div id="chris" className="member">              
                  <button className="toggle" data-bio='chris'><span>Read Bio</span></button>                                
                  <div className="hold">
                    <img src={chris} alt='Chris Betz' />
                    <h3>Chris Betz <span>Chief Technology Officer</span></h3>
                    <div className="bio">
                      <div>
                        <p>Chris Betz is the Chief Technology Officer of aptihealth. Before aptihealth, Chris served as Senior Director of Software Engineering at Brightside Health, an award-wining online mental health treatment platform. Prior to Brightside, he was the CTO of New Ocean Health where he built a Health Cloud for Chronic and Well-Being solutions. He also played a crucial role in shaping the company’s strategy and vision.</p>
                        <p>Previously, Chris was a Senior Software Engineer at Take Care Health Systems which was later acquired by Walgreens. At Walgreens he worked on innovative mobile applications, API-first architectures, and other cutting-edge software products.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col">
                <div id="amanda" className="member">              
                  <button className="toggle" data-bio='amanda'><span>Read Bio</span></button>                                
                  <div className="hold">
                    <img src={amanda} alt='Amanda Conway' />
                    <h3>Amanda Conway <span>Chief Growth Officer</span></h3>
                    <div className="bio">
                      <div>
                        <p>Amanda Conway is aptihealth&rsquo;s Chief Growth Officer. Prior to joining aptihealth, Amanda was Chief Growth Officer at Aduro, where she led transformative change in development and execution of strategic growth plans spanning sales, marketing, and account management. Under her guidance, a new go-to-market strategy was introduced, the sales team was fortified, and a robust pipeline of growth was cultivated.</p>
                        <p>Prior to Aduro Amanda held pivotal leadership roles at Talkspace and Healthie Inc., that resulted in reaching successful growth milestones including growing Healthie from ground zero to $10M in less than 3 years as well as Talkspace going public. At both organizations she led enterprise strategy, built high performing sales and account teams and worked cross functionally to impact all areas of revenue growth.</p>
                        <p>Amanda is a Registered Dietitian and owned and operated her own virtual private practice specializing in behavior change.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
              
              
              <h2 className="text-center pt30">Board Members</h2>
              
              <div className="three-col team">
              <div className="col">
                <div id="peter" className="member">
                <button className="toggle" data-bio='peter'><span>Read Bio</span></button>                                
                <div className="hold">
                  <img src={peter} alt='Peter Dorsman' />
                  <h3>Peter Dorsman <span>Chairman</span></h3>
                  <div className="bio">
                    <div>
                      <p>Peter is a public and private company director with extensive global business experience. Functional expertise includes strategy, supply chain, services, sales, marketing and continuous improvement.</p>
                      <p>Peter retired from NCR Corporation, a global technology company, in April 2014, During his tenure with NCR he served as executive vice president, global services, executive vice  president, global operations, Chief Quality Officer and executive vice president, customer experience and continuous improvement.</p>
                      <p>Peter currently serves on the board of directors for Applied Industrial Technologies (NYSE:AIT) where he chairs the Executive Organization and Compensation Committee and is a member of the Governance Committee, HD Supply (NASDAQ:HDS) where he is Lead Independent Director and a member of the Compensation and Governance Committees and IDEAL Industries where he chairs the Compensation Committee and is a member of the Audit Committee.</p>
                    </div>
                  </div>
                  </div>
                </div>
              </div>

              <div className="col">
                <div id="danBoard" className="member">
                <button className="toggle" data-bio='danBoard'><span>Read Bio</span></button>                                
                <div className="hold">
                  <img src={dan} alt='Daniel T. Pickett III'/>
                  <h3>Daniel T. Pickett III <span>Director</span></h3>
                  <div className="bio">
                    <div>
                      <p>Dan Pickett III is a co-founder and the CEO of aptihealth. Dan joined aptihealth from Hudson River Capital Holdings, an aptihealth founding investor, where he was President. Prior to Hudson River Capital Holdings, he was President of Zones, a $2.4B global IT company. Dan joined Zones when it acquired nfrastructure, a technology company he founded and served as Chief Executive Officer and Chairman of the Board.</p>
                      <p>Prior to nfrastructure, Dan co-founded CIGNA Software Sciences, an expert system and decision support fintech company that was acquired by Alltel Information Services. He is a trustee of Rensselaer Polytechnic Institute and serves on the boards of aptihealth, The Pickett Family Foundation, Albany Medical Center and Albany Medical College.</p>
                    </div>
                  </div>
                  </div>
                </div>
              </div>

              <div className="col">
                <div id="holly" className="member">
                <button className="toggle" data-bio='holly'><span>Read Bio</span></button>                                
                <div className="hold">
                  <img src={holly} alt='Holly Benson'/>
                  <h3>Holly Benson <span>Director</span></h3>
                  <div className="bio">
                    <div>
                      <p>Holly Benson served as EVP, President and CEO of Centene Health Care Enterprises where she oversees the operations of AcariaHealth, Casenet, Interpreta, LifeShare and USMM. Centene owns all or a portion of each of those companies, and Holly is responsible for ensuring that those companies not only provide quality service to Centene&rsquo;s health plans but also to external customers and that those companies continue to grow and develop innovative new products and services.</p>
                      <p>Holly joined Centene in March 2011 as Centene&rsquo;s Senior Vice President of Health Policy. In that role, she is responsible for the development of policy positions for the company and works with health plan and specialty company presidents to develop Centene&rsquo;s policy agenda. She also works with the business development team to craft policy solutions in new markets.</p>
                      <p>She previously served as Secretary for the Florida Agency for Health Care Administration; the state agency responsible for Florida&rsquo;s Medicaid program. She also served as Secretary for the Florida Department of Business and Professional Regulation, and she served as a member of the Florida House of Representatives. During her time in the House, Ms. Benson served as Chair of the Health Care Council, of the Select Committee on Article V, and of the Committee on State Administration. Ms. Benson also spent 10 years practicing municipal bond law with Miller, Canfield, Paddock and Stone, P.L.C.</p>
                      <p>Ms. Benson graduated with honors from Dartmouth College and earned her law degree from the University of Florida - College of Law.</p>
                    </div>
                  </div>
                  </div>
                </div>
              </div>               

              <div className="col">
                <div id="bob" className="member">
                <button className="toggle" data-bio='bob'><span>Read Bio</span></button>                                
                <div className="hold">
                  <img src={bob} alt='Bob Edwards'/>
                  <h3>Bob Edwards <span>Director</span></h3>
                  <div className="bio">
                    <div>
                      <p>Bob is CEO at e5 Pharma and founder of Mizner Bioscience.  Bob is a seasoned pharmaceutical entrepreneur and visionary with a proven track record of generating superior results and has overseen all aspects of the pharmaceutical business including development, manufacturing, distribution, sales, and logistics. Bob has extensive experience with transactional bankers, private equity, and corporate development. In addition to the pharmaceutical business, Bob owns a thoroughbred racing stable that races and breeds horses globally. His horses have won 3 Breeders&rsquo; Cup World Championship races, fourteen Graded Stakes, and an Eclipse Award. Bob was also named TOBA National owner of the year.</p>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
                  

              <div className="col">
                <div id="mary" className="member">
                <button className="toggle" data-bio='mary'><span>Read Bio</span></button>                                
                <div className="hold">
                  <img src={mary} alt='Mary Langowski'/>
                  <h3>Mary Langowski <span>Director</span></h3>
                  <div className="bio">
                    <div>
                      <p>Mary Langowski is a nationally known healthcare executive with a successful track record leveraging market and public sector expertise to grow top-line revenue, drive new market opportunities and build successful organizations and new business models. She is currently the CEO of Solera Health.</p>
                      <p>She founded and built Rising Tide, LLC, a healthcare consultancy working with the most innovative companies leading change in the healthcare marketplace. As EVP/Chief Strategy and Corporate Development Officer at CVS Health, Langowski helped facilitate the organization&rsquo;s evolution to a healthcare company, managed its growth strategy and led the foundational work for the Aetna acquisition.</p>
                      <p>Langowski spent a decade building and running successful multi-million-dollar consulting businesses working with C-suites of start-ups, Fortune 500 organizations and investors in the health and food sectors on their most critical business issues, with an emphasis on new payment models that enable health care innovation and value-based payment.</p>
                      <p>Langowski has served in both state and federal government in senior positions. She currently chairs Rising Tide and serves on the boards of Advantia Health and Solera Health.</p>
                    </div>
                  </div>
                  </div>
                </div>
              </div>                              
                        
              <div className="col">
                <div id="bruce" className="member">
                <button className="toggle" data-bio='bruce'><span>Read Bio</span></button>                                
                <div className="hold">
                  <img src={bruce} alt='Bruce Meadows'/>
                  <h3>Bruce Meadows <span>Director</span></h3>
                  <div className="bio">
                    <div>
                      <p>Bruce serves as the Managing Partner at Takeda Digital Ventures. A seasoned executive, he has served in leadership roles at several healthcare investment funds and portfolio companies. </p>
                      <p>Prior to Takeda Digital Ventures, Bruce was the Senior Investment Director of MTIP, a Swiss based healthcare fund. He&rsquo;s also held pivotal positions at MD Start, Ares Life Sciences, Stryker Orthopedics, N M Rothschild, and GBS Ventures. </p>
                      <p>In his spare time, Bruce has been a part-time Professor of Entrepreneurial Finance at Webster University where he was able to give back to the next generation of entrepreneurs and help them be better equipped for success. Bruce has an MBA from IMD in Lausanne (Switzerland) and a First Class Honors Degree in Biotechnology from the University of Queensland (Australia).</p>
                    </div>
                  </div>
                  </div>
                </div>
              </div>                         
                                         
              
            </div>             
              
          </div>
        </section>
        
        
                        
				<Footer/>

			</article>
    );
  }
}

export default About;
